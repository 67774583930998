import React, { useEffect, useState } from 'react';

const NotesModal = props => {
  const [Notes, setNotes] = useState('');
  useEffect(() => {
    setNotes(props.notes);
  }, []);
  function addnotes(value) {
    setNotes(value);
    props.addnotes(value);
  }
  return (
    <>
      <textarea
        value={Notes}
        style={{ width: '100%' }}
        onChange={e => addnotes(e.target.value)}
      />
    </>
  );
};

export default NotesModal;
