import React, { useState, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfModal = () => {
  const [initialWidth, setInitialWidth] = useState(null);
  const pdfWrapper = useRef();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(null);

  const setPdfSize = () => {
    setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
  };

  useEffect(() => {
    const loadPDF = async () => {
      const response = await fetch('/assets/Sample-pdf.pdf');
      const data = await response.blob();
      const pdfFile = new File([data], 'pdfFile.pdf', {
        type: 'application/pdf',
      });
      setFile(pdfFile);
    };
    loadPDF();
    window.addEventListener('resize', throttle(setPdfSize, 3000));
    setPdfSize();
    return () => {
      window.removeEventListener('resize', throttle(setPdfSize, 3000));
    };
  }, []);

  return (
    <div id="pdfWrapper" style={{ width: '100%' }} ref={pdfWrapper}>
      <Document
        file={file}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={initialWidth}
          />
        ))}
      </Document>
    </div>
  );
};

export default PdfModal;
