import React, { useEffect } from 'react';
import { VoiceCommands } from './defaultVoiceConfig';
import { useTranslation } from 'react-i18next';

import './AboutContent.styl';

const AboutVoice = () => {
  useEffect(() => {
    const commands = localStorage.getItem('commands');
    if (!commands) {
      localStorage.setItem('commands', JSON.stringify(VoiceCommands));
    }
  }, []);

  const { t } = useTranslation('AboutContent');

  const capitalize = s =>
    s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();

  const itemsPreset = () => {
    const commands = localStorage.getItem('commands');
    const vc = JSON.parse(commands);
    return vc;
  };

  const renderTableRow = ({ name, value, defaultValue }) => (
    <tr key={name} style={{ backgroundColor: 'transparent' }}>
      <td>{name}</td>
      <td style={{ color: 'var(--active-color)' }}>
        {value ? value : defaultValue}
      </td>
    </tr>
  );

  return (
    <div className="AboutContent" data-cy="about-modal">
      <div>
        <h4>
          {t('You can change voice commands in ')}
          <span style={{ color: 'var(--active-color)' }}>
            {t('Preferences > Voice Commands')}
          </span>
        </h4>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>{t('Action')}</th>
              <th>{t('Voice Command')}</th>
            </tr>
          </thead>
          <tbody>{itemsPreset().map(item => renderTableRow(item))}</tbody>
        </table>
      </div>
    </div>
  );
};

export { AboutVoice };
export default AboutVoice;
