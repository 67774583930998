export const VoiceCommands = [
  {
    value: '',
    command: 'scaleUpViewport',
    defaultValue: 'zoom in',
    name: 'Scale Up Viewport',
  },
  {
    value: '',
    command: 'scaleDownViewport',
    defaultValue: 'zoom out',
    name: 'Scale Down Viewport',
  },
  {
    value: '',
    command: 'nextImage',
    defaultValue: 'next image',
    name: 'Next Image',
  },
  {
    value: '',
    command: 'previousImage',
    defaultValue: 'previous image',
    name: 'Previous Image',
  },
  {
    value: '',
    command: 'rotateViewportCW',
    defaultValue: 'rotate clockwise',
    name: 'Clockwise Rotation',
  },
  {
    value: '',
    command: 'rotateViewportCCW',
    defaultValue: 'rotate anti clockwise',
    name: 'Anti Clockwise Rotation',
  },
  {
    value: '',
    command: 'invertViewport',
    defaultValue: 'invert viewport',
    name: 'Invert Viewport',
  },
  {
    value: '',
    command: 'resetViewport',
    defaultValue: 'reset viewport',
    name: 'Reset Viewport',
  },
  {
    value: '',
    command: 'flipViewportVertical',
    defaultValue: 'flip vertical',
    name: 'Flip Viewport Vertically',
  },
  {
    value: '',
    command: 'flipViewportHorizontal',
    defaultValue: 'flip horizontal',
    name: 'Flip Viewport Horizontally',
  },
  {
    value: '',
    command: 'previousViewportDisplaySet',
    defaultValue: 'previous series',
    name: 'Previous Series',
  },
  {
    value: '',
    command: 'nextViewportDisplaySet',
    defaultValue: 'next series',
    name: 'Next Series',
  },
  {
    value: '',
    command: 'fitViewportToWindow',
    defaultValue: 'fit image',
    name: 'Fit Viewport',
  },
  {
    value: '',
    command: 'jumpImg',
    defaultValue: 'jump to image *number',
    name: 'Jump to Image',
  },
];
