import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';
import csMath from 'cornerstone-math';
import {
  getEnabledElement,
  getSyncViewports,
  setSyncPoint,
  getSyncPoint,
} from './state';
import waitForTheImageToBeRendered from './utils/waitForTheImageToBeRendered';

import { log, utils } from '@ohif/core';
const { StackManager } = utils;

const draw = csTools.importInternal('drawing/draw');
const drawCircle = csTools.importInternal('drawing/drawCircle');
const drawLine = csTools.importInternal('drawing/drawLine');
const convertToVector3 = csTools.importInternal('util/convertToVector3');
const imagePointToPatientPoint = csTools.importInternal(
  'util/imagePointToPatientPoint'
);
const projectPatientPointToImagePlane = csTools.importInternal(
  'util/projectPatientPointToImagePlane'
);
const getNewContext = csTools.importInternal('drawing/getNewContext');

const ALPHA = 0.001;
const SYNC_POINT_RADIUS = 6;

async function syncViewports(referenceElement, targetNormal, targetImagePlane) {
  if (getSyncViewports()) {
    // Sync frame/slice displayed with target image...
    //
    try {
      const referenceImage = referenceElement.image;
      const SeriesInstanceUID = cornerstone.metaData.get(
        'SeriesInstanceUID',
        referenceImage.imageId
      );
      const StudyInstanceUID = cornerstone.metaData.get(
        'StudyInstanceUID',
        referenceImage.imageId
      );
      const viewportSpecificData = window.store.getState().viewports
        .viewportSpecificData;
      const viewportIndexKeys = Object.keys(viewportSpecificData);
      for (let v = 0; v < viewportIndexKeys.length; v++) {
        let _viewportSpecificData = viewportSpecificData[viewportIndexKeys[v]];
        if (
          _viewportSpecificData.StudyInstanceUID == StudyInstanceUID &&
          _viewportSpecificData.SeriesInstanceUID == SeriesInstanceUID
        ) {
          // Find closest slice in stack to target position
          //
          let stack = StackManager.findStack(
            _viewportSpecificData.displaySetInstanceUID
          );
          let minDistance = 999999;
          let minS = -1;
          const targetIpp = targetImagePlane.imagePositionPatient;
          for (let s = 0; s < stack.imageIds.length; s++) {
            const _referenceImagePlane = cornerstone.metaData.get(
              'imagePlaneModule',
              stack.imageIds[s]
            );

            // Make sure the target and reference actually have image plane metadata
            if (
              !_referenceImagePlane ||
              !_referenceImagePlane.rowCosines ||
              !_referenceImagePlane.columnCosines ||
              !_referenceImagePlane.imagePositionPatient
            ) {
              continue;
            }

            if (
              targetImagePlane.frameOfReferenceUID !==
              _referenceImagePlane.frameOfReferenceUID
            ) {
              continue;
            }

            // Check that this slice has similar orientation as target.
            //
            _referenceImagePlane.rowCosines = convertToVector3(
              _referenceImagePlane.rowCosines
            );
            _referenceImagePlane.columnCosines = convertToVector3(
              _referenceImagePlane.columnCosines
            );

            const _referenceNormal = _referenceImagePlane.rowCosines
              .clone()
              .cross(_referenceImagePlane.columnCosines);
            let angleInRadians = Math.abs(
              targetNormal.angleTo(_referenceNormal)
            );
            if (angleInRadians >= 0.5) {
              // Make sure any stale ref line is removed.
              //
              cornerstone.updateImage(referenceElement.element);
              continue;
            }

            // +TODO+ Calculate perpendicular distance to targetImage plane, use center or
            // selected point.
            //
            let _referenceIpp = convertToVector3(
              _referenceImagePlane.imagePositionPatient
            );
            let distance = Math.sqrt(
              Math.pow(targetIpp.x - _referenceIpp.x, 2) +
                Math.pow(targetIpp.y - _referenceIpp.y, 2) +
                Math.pow(targetIpp.z - _referenceIpp.z, 2)
            );
            if (distance < minDistance) {
              minDistance = distance;
              minS = s;
            }
          }

          if (minS != -1) {
            const imageId = stack.imageIds[minS];
            if (referenceImage.imageId != imageId) {
              cornerstone.loadImage(imageId).then(image => {
                cornerstone.displayImage(referenceElement.element, image);
              });
            }
          }
          break;
        }
      }
    } catch (err) {
      log.warn('Could not synchronize with target: ' + err.message);
    }
  }
  return;
}

async function syncViewportsToPoint(
  referenceElement,
  targetImagePlane,
  targetPoint
) {
  if (getSyncViewports()) {
    // Sync frame/slice displayed with target image...
    //
    try {
      const referenceImage = referenceElement.image;
      const SeriesInstanceUID = cornerstone.metaData.get(
        'SeriesInstanceUID',
        referenceImage.imageId
      );
      const StudyInstanceUID = cornerstone.metaData.get(
        'StudyInstanceUID',
        referenceImage.imageId
      );
      const viewportSpecificData = window.store.getState().viewports
        .viewportSpecificData;
      const viewportIndexKeys = Object.keys(viewportSpecificData);
      for (let v = 0; v < viewportIndexKeys.length; v++) {
        let _viewportSpecificData = viewportSpecificData[viewportIndexKeys[v]];
        if (
          _viewportSpecificData.StudyInstanceUID == StudyInstanceUID &&
          _viewportSpecificData.SeriesInstanceUID == SeriesInstanceUID
        ) {
          // Find closest slice in stack to target position
          //
          let stack = StackManager.findStack(
            _viewportSpecificData.displaySetInstanceUID
          );
          let minDistance = 999999;
          let minS = -1;
          for (let s = 0; s < stack.imageIds.length; s++) {
            const _referenceImagePlane = cornerstone.metaData.get(
              'imagePlaneModule',
              stack.imageIds[s]
            );

            // Make sure the target and reference actually have image plane metadata
            if (
              !_referenceImagePlane ||
              !_referenceImagePlane.rowCosines ||
              !_referenceImagePlane.columnCosines ||
              !_referenceImagePlane.imagePositionPatient
            ) {
              continue;
            }

            if (
              targetImagePlane.frameOfReferenceUID !==
              _referenceImagePlane.frameOfReferenceUID
            ) {
              continue;
            }

            // Check that this slice has similar orientation as target.
            //
            _referenceImagePlane.rowCosines = convertToVector3(
              _referenceImagePlane.rowCosines
            );
            _referenceImagePlane.columnCosines = convertToVector3(
              _referenceImagePlane.columnCosines
            );

            const _referenceNormal = _referenceImagePlane.rowCosines
              .clone()
              .cross(_referenceImagePlane.columnCosines);

            // Calculate perpendicular distance from target point to reference point.
            //
            let _referenceIpp = convertToVector3(
              _referenceImagePlane.imagePositionPatient
            );
            const v1 = targetPoint.clone().sub(_referenceIpp);
            const distance = Math.abs(v1.dot(_referenceNormal));
            if (distance < minDistance) {
              minDistance = distance;
              minS = s;
            }
          }

          if (minS != -1) {
            const imageId = stack.imageIds[minS];
            if (referenceImage.imageId != imageId) {
              cornerstone.loadImage(imageId).then(image => {
                cornerstone.displayImage(referenceElement.element, image);
              });
            }
          }
          break;
        }
      }
    } catch (err) {
      log.warn('Could not synchronize with target: ' + err.message);
    }
  }
  return;
}

const enableReferenceLines = () => {
  const renderReferenceLines = ({ detail: { enabledElement } }) => {
    const { activeViewportIndex } = window.store.getState().viewports;

    if (getEnabledElement(activeViewportIndex) !== enabledElement.element)
      return;

    const targetImage = enabledElement.image;
    var n = 0;
    cornerstone
      .getEnabledElements()
      .filter(e => e.uuid !== enabledElement.uuid)
      .forEach(async referenceElement => {
        if (!referenceElement.image)
          await waitForTheImageToBeRendered(referenceElement.element);

        const referenceImage = referenceElement.image;

        if (!referenceImage || !targetImage) {
          log.warn(
            'Could not render reference lines, one or more images not defined.'
          );
          return;
        }

        const targetImagePlane = cornerstone.metaData.get(
          'imagePlaneModule',
          targetImage.imageId
        );
        const referenceImagePlane = cornerstone.metaData.get(
          'imagePlaneModule',
          referenceImage.imageId
        );
        // Make sure the target and reference actually have image plane metadata
        if (
          !targetImagePlane ||
          !targetImagePlane.rowCosines ||
          !targetImagePlane.columnCosines ||
          !targetImagePlane.imagePositionPatient
        ) {
          log.warn(
            `Could not render reference lines/point, target image plane modules not defined.`
          );
          return;
        }
        if (
          !referenceImagePlane ||
          !referenceImagePlane.rowCosines ||
          !referenceImagePlane.columnCosines ||
          !referenceImagePlane.imagePositionPatient
        ) {
          log.warn(
            `Could not render reference lines/point, reference image plane modules not defined.`
          );
          return;
        }

        if (
          targetImagePlane.frameOfReferenceUID !==
          referenceImagePlane.frameOfReferenceUID
        ) {
          return;
        }

        const sliceThickness = referenceImagePlane.sliceThickness || 1.0;
        let referenceLines = [];

        targetImagePlane.rowCosines = convertToVector3(
          targetImagePlane.rowCosines
        );
        targetImagePlane.columnCosines = convertToVector3(
          targetImagePlane.columnCosines
        );
        targetImagePlane.imagePositionPatient = convertToVector3(
          targetImagePlane.imagePositionPatient
        );
        referenceImagePlane.rowCosines = convertToVector3(
          referenceImagePlane.rowCosines
        );
        referenceImagePlane.columnCosines = convertToVector3(
          referenceImagePlane.columnCosines
        );
        referenceImagePlane.imagePositionPatient = convertToVector3(
          referenceImagePlane.imagePositionPatient
        );
        // The image plane normals must be > 30 degrees apart
        const targetNormal = targetImagePlane.rowCosines
          .clone()
          .cross(targetImagePlane.columnCosines);
        const referenceNormal = referenceImagePlane.rowCosines
          .clone()
          .cross(referenceImagePlane.columnCosines);
        let angleInRadians = Math.abs(targetNormal.angleTo(referenceNormal));
        if (angleInRadians < 0.5) {
          log.info(`skipping ref lines, angle=${angleInRadians} rad.`);
          syncViewports(referenceElement, targetNormal, targetImagePlane);
        } else {
          const topLeft = imagePointToPatientPoint(
            { x: 0, y: 0 },
            targetImagePlane
          );
          const topCenter = imagePointToPatientPoint(
            { x: targetImagePlane.columns / 2, y: 0 },
            targetImagePlane
          );
          const topRight = imagePointToPatientPoint(
            { x: targetImagePlane.columns, y: 0 },
            targetImagePlane
          );
          const centerLeft = imagePointToPatientPoint(
            { x: 0, y: targetImagePlane.rows / 2 },
            targetImagePlane
          );
          const centerRight = imagePointToPatientPoint(
            { x: targetImagePlane.columns, y: targetImagePlane.rows / 2 },
            targetImagePlane
          );
          const bottomLeft = imagePointToPatientPoint(
            { x: 0, y: targetImagePlane.rows },
            targetImagePlane
          );
          const bottomCenter = imagePointToPatientPoint(
            { x: targetImagePlane.columns / 2, y: targetImagePlane.rows },
            targetImagePlane
          );
          const bottomRight = imagePointToPatientPoint(
            { x: targetImagePlane.columns, y: targetImagePlane.rows },
            targetImagePlane
          );

          referenceLines.push({
            start: projectPatientPointToImagePlane(
              topLeft,
              referenceImagePlane
            ),
            end: projectPatientPointToImagePlane(topRight, referenceImagePlane),
            color: 'greenyellow',
          });
          referenceLines.push({
            start: projectPatientPointToImagePlane(
              topLeft,
              referenceImagePlane
            ),
            end: projectPatientPointToImagePlane(
              bottomLeft,
              referenceImagePlane
            ),
            color: 'orange',
          });
          referenceLines.push({
            start: projectPatientPointToImagePlane(
              topRight,
              referenceImagePlane
            ),
            end: projectPatientPointToImagePlane(
              bottomRight,
              referenceImagePlane
            ),
            color: 'orange',
          });
          referenceLines.push({
            start: projectPatientPointToImagePlane(
              bottomLeft,
              referenceImagePlane
            ),
            end: projectPatientPointToImagePlane(
              bottomRight,
              referenceImagePlane
            ),
            color: 'greenyellow',
          });

          let distance = Math.sqrt(
            Math.pow(topCenter.x - bottomCenter.x, 2) +
              Math.pow(topCenter.y - bottomCenter.y, 2) +
              Math.pow(topCenter.z - bottomCenter.z, 2)
          );
          if (distance > sliceThickness) {
            referenceLines.push({
              start: projectPatientPointToImagePlane(
                topCenter,
                referenceImagePlane
              ),
              end: projectPatientPointToImagePlane(
                bottomCenter,
                referenceImagePlane
              ),
              color: 'blue',
            });
          }
          distance = Math.sqrt(
            Math.pow(centerLeft.x - centerRight.x, 2) +
              Math.pow(centerLeft.y - centerRight.y, 2) +
              Math.pow(centerLeft.z - centerRight.z, 2)
          );
          if (distance > sliceThickness) {
            referenceLines.push({
              start: projectPatientPointToImagePlane(
                centerLeft,
                referenceImagePlane
              ),
              end: projectPatientPointToImagePlane(
                centerRight,
                referenceImagePlane
              ),
              color: 'blue',
            });
          }
        }

        let syncPointCenter = null;
        let syncPointColor = 'greenyellow';
        const syncPoint = getSyncPoint();
        if (
          syncPoint.frameOfReferenceUID ==
          referenceImagePlane.frameOfReferenceUID
        ) {
          const v1 = syncPoint.patientPoint
            .clone()
            .sub(referenceImagePlane.imagePositionPatient);
          const distanceToSlice = Math.abs(v1.dot(referenceNormal));
          const center = projectPatientPointToImagePlane(
            syncPoint.patientPoint,
            referenceImagePlane
          );
          if (
            distanceToSlice < sliceThickness &&
            center.x >= 0 &&
            center.x < referenceImage.columns &&
            center.y >= 0 &&
            center.y < referenceImage.rows
          ) {
            syncPointColor = distanceToSlice > ALPHA ? 'orange' : 'greenyellow';
            syncPointCenter = center;
          } else {
            log.info(
              `1 skip sync pt d=${distanceToSlice} st=${sliceThickness} x,y=(${center.x},${center.y}) r,c=(${referenceImage.rows}, ${referenceImage.columns})`
            );
          }
        }

        const onReferenceElementImageRendered = () => {
          const context = getNewContext(referenceElement.canvas);
          for (var l = 0; l < referenceLines.length; l++) {
            context.setTransform(1, 0, 0, 1, 0, 0);
            draw(context, newContext => {
              drawLine(
                newContext,
                referenceElement.element,
                referenceLines[l].start,
                referenceLines[l].end,
                { color: referenceLines[l].color }
              );
            });
          }

          if (syncPointCenter !== null) {
            context.setTransform(1, 0, 0, 1, 0, 0);
            draw(context, newContext => {
              drawCircle(
                newContext,
                referenceElement.element,
                syncPointCenter,
                SYNC_POINT_RADIUS,
                { color: syncPointColor },
                'pixel'
              );
            });
          }

          referenceElement.element.removeEventListener(
            cornerstone.EVENTS.IMAGE_RENDERED,
            onReferenceElementImageRendered
          );
        };

        referenceElement.element.addEventListener(
          cornerstone.EVENTS.IMAGE_RENDERED,
          onReferenceElementImageRendered
        );
        cornerstone.updateImage(referenceElement.element);
      });
  };

  const handleFullScreen = event => {
    const detail = event.detail;
    const { activeViewportIndex } = window.store.getState().viewports;
    const element = getEnabledElement(activeViewportIndex);
    if (!element) {
      return;
    }
    if (element !== detail.element) {
      log.info('Ignoring event in non-active element');
      return;
    }

    // Toggle normal and fullscreen modes.
    //
    if (
      typeof document.fullscreenElement !== 'undefined' &&
      document.fullscreenElement != null
    ) {
      document.exitFullscreen();
    } else if (
      typeof document.webkitFullscreenElement !== 'undefined' &&
      document.webkitFullscreenElement != null
    ) {
      document.webkitExitFullscreen();
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else {
      log.info('This browser does not support fullscreen mode.');
    }
  };

  const renderReferencePoint = event => {
    const detail = event.detail;
    const { activeViewportIndex } = window.store.getState().viewports;
    const element = getEnabledElement(activeViewportIndex);
    if (!element) {
      return;
    }
    if (element !== detail.element) {
      log.info('Ignoring event in non-active element');
      return;
    }
    const enabledElement = cornerstone.getEnabledElement(element);
    if (!enabledElement || !enabledElement.image) {
      return;
    }
    const targetImage = enabledElement.image;
    if (!targetImage) {
      log.info('Could not render reference point, target image not defined.');
      return;
    }
    const targetImagePlane = cornerstone.metaData.get(
      'imagePlaneModule',
      targetImage.imageId
    );
    // Make sure the target actually has image plane metadata
    if (
      !targetImagePlane ||
      !targetImagePlane.rowCosines ||
      !targetImagePlane.columnCosines ||
      !targetImagePlane.imagePositionPatient
    ) {
      log.info(
        'Could not render reference point, target image plane modules not defined.'
      );
      log.info(targetImagePlane);
      return;
    }

    const targetRowSpacing =
      targetImagePlane.rowPixelSpacing || targetImagePlane.rowImagePixelSpacing;
    const targetColSpacing =
      targetImagePlane.columnPixelSpacing ||
      targetImagePlane.colImagePixelSpacing;
    const targetRowCosines = convertToVector3(targetImagePlane.rowCosines);
    const targetColumnCosines = convertToVector3(
      targetImagePlane.columnCosines
    );
    const targetIpp = convertToVector3(targetImagePlane.imagePositionPatient);

    // Find (X,Y,Z) in patient coordinate system for the selected point.
    //
    const selectedPoint = imagePointToPatientPoint(
      detail.currentPoints.image,
      targetImagePlane
    );
    setSyncPoint({
      frameOfReferenceUID: targetImagePlane.frameOfReferenceUID,
      patientPoint: selectedPoint,
    });

    // Loop through all other images...
    //
    cornerstone.getEnabledElements().forEach(async referenceElement => {
      const referenceImage = referenceElement.image;
      if (!referenceImage) {
        return;
      }
      if (event.type == csTools.EVENTS.MOUSE_DOWN && detail.buttons != 1) {
        setSyncPoint({
          frameOfReferenceUID: '_NoT_VaLiD_',
          patientPoint: { x: 0, y: 0, z: 0 },
        });
        cornerstone.updateImage(referenceElement.element);
        return;
      }

      const referenceImagePlane = cornerstone.metaData.get(
        'imagePlaneModule',
        referenceImage.imageId
      );
      // Make sure the reference actually has image plane metadata
      if (
        !referenceImagePlane ||
        !referenceImagePlane.rowCosines ||
        !referenceImagePlane.columnCosines ||
        !referenceImagePlane.imagePositionPatient
      ) {
        //log.warn(
        //  'Could not render reference point, reference image plane modules not defined.'
        //);
        return;
      }

      const referenceRowSpacing =
        referenceImagePlane.rowPixelSpacing ||
        referenceImagePlane.rowImagePixelSpacing;
      const referenceColSpacing =
        referenceImagePlane.columnPixelSpacing ||
        referenceImagePlane.colImagePixelSpacing;
      const referenceRowCosines = convertToVector3(
        referenceImagePlane.rowCosines
      );
      const referenceColumnCosines = convertToVector3(
        referenceImagePlane.columnCosines
      );
      const referenceIpp = convertToVector3(
        referenceImagePlane.imagePositionPatient
      );
      if (
        targetImagePlane.frameOfReferenceUID ==
          referenceImagePlane.frameOfReferenceUID &&
        targetColumnCosines
          .clone()
          .sub(referenceColumnCosines)
          .lengthSq() < ALPHA &&
        targetRowCosines
          .clone()
          .sub(referenceRowCosines)
          .lengthSq() < ALPHA
      ) {
        if (
          targetIpp
            .clone()
            .sub(referenceIpp)
            .lengthSq() < ALPHA &&
          Math.abs(targetRowSpacing - referenceRowSpacing) < ALPHA &&
          Math.abs(targetColSpacing - referenceColSpacing) < ALPHA
        ) {
          // SAME RESOLUTION, SAME POSITION - just plot x,y
          //
          const x = detail.currentPoints.image.x;
          const y = detail.currentPoints.image.y;
          if (
            x >= 0 &&
            x < referenceImage.columns &&
            y >= 0 &&
            y < referenceImage.rows
          ) {
            const onReferenceElementImageRendered = () => {
              const context = getNewContext(referenceElement.canvas);
              context.setTransform(1, 0, 0, 1, 0, 0);
              draw(context, newContext => {
                const center = {
                  x: detail.currentPoints.image.x,
                  y: detail.currentPoints.image.y,
                };
                drawCircle(
                  newContext,
                  referenceElement.element,
                  center,
                  SYNC_POINT_RADIUS,
                  { color: 'greenyellow' },
                  'pixel'
                );
              });

              referenceElement.element.removeEventListener(
                cornerstone.EVENTS.IMAGE_RENDERED,
                onReferenceElementImageRendered
              );
            };

            referenceElement.element.addEventListener(
              cornerstone.EVENTS.IMAGE_RENDERED,
              onReferenceElementImageRendered
            );
          }
        } else {
          // DIFFERENT RESOLUTION and/or DIFFERENT POSITION - find closest point on slice plane.
          //
          const center = projectPatientPointToImagePlane(
            selectedPoint,
            referenceImagePlane
          );
          const referenceNormal = referenceRowCosines
            .clone()
            .cross(referenceColumnCosines);
          const sliceThickness = referenceImagePlane.sliceThickness || 1.0;
          const v1 = selectedPoint.clone().sub(referenceIpp);
          const distanceToSlice = Math.abs(v1.dot(referenceNormal));

          if (
            distanceToSlice < sliceThickness &&
            center.x >= 0 &&
            center.x < referenceImage.columns &&
            center.y >= 0 &&
            center.y < referenceImage.rows
          ) {
            const color = distanceToSlice > ALPHA ? 'orange' : 'greenyellow';
            const onReferenceElementImageRendered = () => {
              const context = getNewContext(referenceElement.canvas);
              context.setTransform(1, 0, 0, 1, 0, 0);
              draw(context, newContext => {
                drawCircle(
                  newContext,
                  referenceElement.element,
                  center,
                  SYNC_POINT_RADIUS,
                  { color: color },
                  'pixel'
                );
              });

              referenceElement.element.removeEventListener(
                cornerstone.EVENTS.IMAGE_RENDERED,
                onReferenceElementImageRendered
              );
            };

            referenceElement.element.addEventListener(
              cornerstone.EVENTS.IMAGE_RENDERED,
              onReferenceElementImageRendered
            );
          } else {
            log.info(
              `2 skip sync pt d=${distanceToSlice} st=${sliceThickness} x,y=(${center.x},${center.y}) r,c=(${referenceImage.rows}, ${referenceImage.columns})`
            );
          }
        }
      } else {
        syncViewportsToPoint(referenceElement, targetImagePlane, selectedPoint);
      }

      // Always update in case old marker needs to be removed from a slice that no longer matches.
      //
      cornerstone.updateImage(referenceElement.element);
    });
  };

  cornerstone.events.addEventListener(
    cornerstone.EVENTS.ELEMENT_ENABLED,
    event => {
      event.detail.element.addEventListener(
        cornerstone.EVENTS.IMAGE_RENDERED,
        renderReferenceLines
      );
      event.detail.element.addEventListener(
        csTools.EVENTS.MOUSE_CLICK,
        renderReferencePoint
      );
      event.detail.element.addEventListener(
        csTools.EVENTS.MOUSE_DOUBLE_CLICK,
        handleFullScreen
      );
      event.detail.element.addEventListener(
        csTools.EVENTS.DOUBLE_TAP,
        handleFullScreen
      );
    }
  );

  cornerstone.events.addEventListener(
    cornerstone.EVENTS.ELEMENT_DISABLED,
    event => {
      event.detail.element.removeEventListener(
        cornerstone.EVENTS.IMAGE_RENDERED,
        renderReferenceLines
      );
      event.detail.element.removeEventListener(
        csTools.EVENTS.MOUSE_CLICK,
        renderReferencePoint
      );
      event.detail.element.removeEventListener(
        csTools.EVENTS.MOUSE_DOUBLE_CLICK,
        handleFullScreen
      );
      event.detail.element.removeEventListener(
        csTools.EVENTS.DOUBLE_TAP,
        handleFullScreen
      );
    }
  );
};

export default enableReferenceLines;
