// TODO: A way to add Icons that don't already exist?
// - Register them and add
// - Include SVG Source/Inline?
// - By URL, or own component?

// What KINDS of toolbar buttons do we have...
// - One's that dispatch commands
// - One's that set tool's active
// - More custom, like CINE
//    - Built in for one's like this, or custom components?

// Visible?
// Disabled?
// Based on contexts or misc. criteria?
//  -- ACTIVE_ROUTE::VIEWER
//  -- ACTIVE_VIEWPORT::CORNERSTONE
// setToolActive commands should receive the button event that triggered
// so we can do the "bind to this button" magic

const TOOLBAR_BUTTON_TYPES = {
  COMMAND: 'command',
  SET_TOOL_ACTIVE: 'setToolActive',
  BUILT_IN: 'builtIn',
};

const TOOLBAR_BUTTON_BEHAVIORS = {
  CINE: 'CINE',
  DOWNLOAD_SCREEN_SHOT: 'DOWNLOAD_SCREEN_SHOT',
  KEY_IMAGE: 'KEY_IMAGE',
  SYNC_VIEWPORTS: 'SYNC_VIEWPORTS',
};

/* TODO: Export enums through a extension manager. */
const enums = {
  TOOLBAR_BUTTON_TYPES,
  TOOLBAR_BUTTON_BEHAVIORS,
};

let definitions = [
  {
    id: 'StackScroll',
    label: 'Stack Scroll',
    icon: 'bars',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'StackScroll' },
  },
  {
    id: 'Zoom',
    label: 'Zoom',
    icon: 'search-plus',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Zoom' },
  },
  {
    id: 'Wwwc',
    label: 'Levels',
    icon: 'level',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Wwwc' },
  },
  {
    id: 'Pan',
    label: 'Pan',
    icon: 'arrows',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Pan' },
  },
  {
    id: 'Length',
    label: 'Length',
    icon: 'measure-temp',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Length' },
  },
  {
    id: 'ArrowAnnotate',
    label: 'Annotate',
    icon: 'measure-non-target',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'ArrowAnnotate' },
  },
  {
    id: 'Angle',
    label: 'Angle',
    icon: 'angle-left',
    //
    type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    commandName: 'setToolActive',
    commandOptions: { toolName: 'Angle' },
  },
  {
    id: 'Reset',
    label: 'Reset',
    icon: 'reset',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'resetViewport',
  },
  {
    id: 'Cine',
    label: 'CINE',
    icon: 'youtube',
    //
    type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
    options: {
      behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
    },
  },
  {
    id: 'More',
    label: 'More',
    icon: 'ellipse-circle',
    buttons: [
      {
        id: 'Magnify',
        label: 'Magnify',
        icon: 'circle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Magnify' },
      },
      {
        id: 'WwwcRegion',
        label: 'ROI Window',
        icon: 'stop',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'WwwcRegion' },
      },
      {
        id: 'DragProbe',
        label: 'Probe',
        icon: 'dot-circle',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'DragProbe' },
      },
      {
        id: 'EllipticalRoi',
        label: 'Ellipse',
        icon: 'circle-o',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'EllipticalRoi' },
      },
      {
        id: 'RectangleRoi',
        label: 'Rectangle',
        icon: 'square-o',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'RectangleRoi' },
      },
      {
        id: 'Invert',
        label: 'Invert',
        icon: 'adjust',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'invertViewport',
      },
      {
        id: 'RotateRight',
        label: 'Rotate Right',
        icon: 'rotate-right',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'rotateViewportCW',
      },
      {
        id: 'FlipH',
        label: 'Flip H',
        icon: 'ellipse-h',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'flipViewportHorizontal',
      },
      {
        id: 'FlipV',
        label: 'Flip V',
        icon: 'ellipse-v',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'flipViewportVertical',
      },
      {
        id: 'Clear',
        label: 'Clear',
        icon: 'trash',
        //
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'clearAnnotations',
      },
      {
        id: 'Bidirectional',
        label: 'Bidirectional',
        icon: 'measure-target',
        //
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        commandName: 'setToolActive',
        commandOptions: { toolName: 'Bidirectional' },
      },
      {
        id: 'Download',
        label: 'Download',
        icon: 'create-screen-capture',
        //
        type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
        options: {
          behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
          togglable: true,
        },
      },
    ],
  },
  {
    id: 'Exit2DMPR',
    label: 'Exit 2D MPR',
    icon: 'times',
    //
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'setCornerstoneLayout',
    context: 'ACTIVE_VIEWPORT::VTK',
  },
];

if (window.innerWidth > 786) {
  definitions = [
    {
      id: 'StackScroll',
      label: 'Stack Scroll',
      icon: 'bars',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'StackScroll' },
    },
    {
      id: 'Zoom',
      label: 'Zoom',
      icon: 'search-plus',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Zoom' },
    },
    {
      id: 'WwwcDrop',
      label: 'Levels',
      icon: 'level',
      buttons: [
        {
          id: 'Wwwc',
          label: 'Levels',
          icon: 'level',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Wwwc' },
        },
        {
          id: 'WwwcPreset1',
          label: 'Soft Tissue',
          icon: 'soft-tissue',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset1',
          commandOptions: { preset: 1 },
        },
        {
          id: 'WwwcPreset2',
          label: 'Lung',
          icon: 'lung',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset2',
          commandOptions: { preset: 2 },
        },
        {
          id: 'WwwcPreset3',
          label: 'Liver',
          icon: 'liver',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset3',
          commandOptions: { preset: 3 },
        },
        {
          id: 'WwwcPreset4',
          label: 'Bone',
          icon: 'bone',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset4',
          commandOptions: { preset: 4 },
        },
        {
          id: 'WwwcPreset5',
          label: 'Brain',
          icon: 'brain',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset5',
          commandOptions: { preset: 5 },
        },
        {
          id: 'WwwcPreset6',
          label: 'Chest and ABD/Pelvis',
          icon: 'lung',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset6',
          commandOptions: { preset: 6 },
        },
        {
          id: 'WwwcPreset7',
          label: 'Head/Neck',
          icon: 'brain',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset7',
          commandOptions: { preset: 7 },
        },
        {
          id: 'WwwcPreset8',
          label: 'Brain T1',
          icon: 'brain',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset8',
          commandOptions: { preset: 8 },
        },
        {
          id: 'WwwcPreset9',
          label: 'Brain T2',
          icon: 'brain',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset9',
          commandOptions: { preset: 9 },
        },
      ],
    },
    {
      id: 'Pan',
      label: 'Pan',
      icon: 'arrows',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Pan' },
    },
    {
      id: 'Length',
      label: 'Length',
      icon: 'measure-temp',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Length' },
    },
    {
      id: 'ArrowAnnotate',
      label: 'Annotate',
      icon: 'measure-non-target',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'ArrowAnnotate' },
    },
    {
      id: 'Angle',
      label: 'Angle',
      icon: 'angle-left',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Angle' },
    },
    {
      id: 'Reset',
      label: 'Reset',
      icon: 'reset',
      //
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'resetViewport',
    },
    {
      id: 'Cine',
      label: 'CINE',
      icon: 'youtube',
      //
      type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
      options: {
        behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
      },
    },
    {
      id: 'EllipticalRoi',
      label: 'Ellipse',
      icon: 'circle-o',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'EllipticalRoi' },
    },
    {
      id: 'More',
      label: 'More',
      icon: 'ellipse-circle',
      buttons: [
        {
          id: 'Magnify',
          label: 'Magnify',
          icon: 'circle',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Magnify' },
        },
        {
          id: 'WwwcRegion',
          label: 'ROI Window',
          icon: 'stop',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'WwwcRegion' },
        },
        {
          id: 'DragProbe',
          label: 'Probe',
          icon: 'dot-circle',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'DragProbe' },
        },

        {
          id: 'RectangleRoi',
          label: 'Rectangle',
          icon: 'square-o',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'RectangleRoi' },
        },
        {
          id: 'Invert',
          label: 'Invert',
          icon: 'adjust',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'invertViewport',
        },
        {
          id: 'RotateRight',
          label: 'Rotate Right',
          icon: 'rotate-right',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'rotateViewportCW',
        },
        {
          id: 'FlipH',
          label: 'Flip H',
          icon: 'ellipse-h',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'flipViewportHorizontal',
        },
        {
          id: 'FlipV',
          label: 'Flip V',
          icon: 'ellipse-v',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'flipViewportVertical',
        },
        {
          id: 'Clear',
          label: 'Clear',
          icon: 'trash',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'clearAnnotations',
        },
        {
          id: 'Bidirectional',
          label: 'Bidirectional',
          icon: 'measure-target',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Bidirectional' },
        },
        {
          id: 'Download',
          label: 'Download',
          icon: 'create-screen-capture',
          //
          type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
          options: {
            behavior: TOOLBAR_BUTTON_BEHAVIORS.DOWNLOAD_SCREEN_SHOT,
            togglable: true,
          },
        },
      ],
    },
    {
      id: 'SyncScroll',
      label: 'Sync',
      icon: 'link',
      //
      type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
      options: {
        behavior: TOOLBAR_BUTTON_BEHAVIORS.SYNC_VIEWPORTS,
      },
    },
    {
      id: 'Exit2DMPR',
      label: 'Exit 2D MPR',
      icon: 'times',
      //
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'setCornerstoneLayout',
      context: 'ACTIVE_VIEWPORT::VTK',
    },
  ];
} else {
  definitions = [
    {
      id: 'StackScroll',
      label: 'Stack Scroll',
      icon: 'bars',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'StackScroll' },
    },
    {
      id: 'Zoom',
      label: 'Zoom',
      icon: 'search-plus',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Zoom' },
    },
    {
      id: 'WwwcDrop',
      label: 'Levels',
      icon: 'level',
      buttons: [
        {
          id: 'Wwwc',
          label: 'Levels',
          icon: 'level',
          //
          type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
          commandName: 'setToolActive',
          commandOptions: { toolName: 'Wwwc' },
        },
        {
          id: 'WwwcPreset1',
          label: 'Soft Tissue',
          icon: 'soft-tissue',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset1',
          commandOptions: { preset: 1 },
        },
        {
          id: 'WwwcPreset2',
          label: 'Lung',
          icon: 'lung',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset2',
          commandOptions: { preset: 2 },
        },
        {
          id: 'WwwcPreset3',
          label: 'Liver',
          icon: 'liver',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset3',
          commandOptions: { preset: 3 },
        },
        {
          id: 'WwwcPreset4',
          label: 'Bone',
          icon: 'bone',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset4',
          commandOptions: { preset: 4 },
        },
        {
          id: 'WwwcPreset5',
          label: 'Brain',
          icon: 'brain',
          //
          type: TOOLBAR_BUTTON_TYPES.COMMAND,
          commandName: 'windowLevelPreset5',
          commandOptions: { preset: 5 },
        },
      ],
    },
    {
      id: 'Pan',
      label: 'Pan',
      icon: 'arrows',
      //
      type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
      commandName: 'setToolActive',
      commandOptions: { toolName: 'Pan' },
    },

    {
      id: 'Cine',
      label: 'CINE',
      icon: 'youtube',
      //
      type: TOOLBAR_BUTTON_TYPES.BUILT_IN,
      options: {
        behavior: TOOLBAR_BUTTON_BEHAVIORS.CINE,
      },
    },
    {
      id: 'Exit2DMPR',
      label: 'Exit 2D MPR',
      icon: 'times',
      //
      type: TOOLBAR_BUTTON_TYPES.COMMAND,
      commandName: 'setCornerstoneLayout',
      context: 'ACTIVE_VIEWPORT::VTK',
    },
  ];
}
console.log(definitions, window.innerWidth);
export default {
  definitions,
  defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
};
